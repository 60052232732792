.parent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.parent:hover .textOverlay {
  opacity: 1;
}

.walkerWrapper {
  overflow: hidden;
  position: relative;
  text-align: center;
  height: 300px;
  width: 250px;
  border-radius: 15px;
}

.walkerImage {
  object-fit: cover;
  height: 300px;
  width: 250px;
}

.textOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  z-index: 1;
}

.description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: grey;
}

.name {
  text-align: center;
}

.Desparked {
  background: red;
}

.Desparked img {
  filter: saturate(0);
  opacity: 0.5;
}

.Unknown img {
  filter: saturate(0);
  opacity: 0.3;
}

.Dead img {
  filter: saturate(0);
}

.Dead:after {
  top: -5%;
  left: 0;
  right: 0;
  position: absolute;
  content: "\d7";
  font-size: 20rem;
  color: maroon;
  font-weight: bold;
}
